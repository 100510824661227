<template>
  <section
    id="welcome"
    class="overflow-hidden"
  >
    <v-img
      class="page-header-image"
      height="70vh"
      :src="require('@/assets/header1.jpg')"
    />
  </section>
</template>
<style (scoped)>
.page-header-image{

  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: 1;

}
</style>
<script>
  export default {
    name: 'RecentProjects',

    data: () => ({
      projects: [
        'project1',
        'project2',
        'project3',
        'project4',
      ],
    }),
  }
</script>
